import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useHeroStyles = makeStyles((theme) => ({
  root: {
    background: colors.white,
    borderRadius: '40px',
    boxShadow: 'none',
    padding: '2px 4px',
    display: 'flex',
    flex: '2',
    alignItems: 'center',
    border: 'none',
    transition: 'background-color 300ms, box-shadow 300ms',
    '&:focus-within': {
      backgroundColor: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      borderRadius: '4px',
      background: 'transparent',
      flexDirection: 'column',
      border: 'none',
      justifyContent: 'flex-start',

      '&:hover': {
        border: 'none',
      },
      '&:focus-within': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: '0px 1px 3px rgb(0 0 0 / 14%)',
      },
    },
  },
  inputContainer: {
    '&:hover': {
      border: 'none',
    },
    border: 'none',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      background: colors.white,
      marginLeft: '0px',
      width: '100%',
      marginBottom: '12px',
      fontSize: '16px',
    },
  },
  input: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  searchInputContainer: {
    minWidth: '265px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '0px',
    },
  },
  autocomplete: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  locationInputContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  locationInput: {
    '&::placeholder': {
      opacity: '.5',
    },
  },
  searchButton: {
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    background: theme.palette.secondary.main,
    color: theme.palette.background.default,
    padding: 10,
    left: '1px',
    width: '46px',
    height: '46px',
    borderRadius: '40px',
    [theme.breakpoints.down('sm')]: {
      height: '48px',
      width: '100%',
      borderRadius: '4px',
      left: '0px',
    },
  },
  searchButtonText: {
    fontWeight: 600,
    fontSize: '16px',
  },
  divider: {
    height: 28,
    margin: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  listBox: {
    backgroundColor: colors.white,
    listStyle: 'none',
    left: -50,
    top: 25,
    width: 'calc(100% + 80px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',

    '& > li': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.gray.l90,
      },
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    background: colors.gray.l96,
    borderRadius: '40px',
    boxShadow: 'none',
    padding: '2px 4px',
    display: 'flex',
    flex: '2',
    alignItems: 'center',
    border: '1px solid transparent',
    transition: 'background-color 300ms, box-shadow 300ms',
    '&:hover': {
      border: `1px solid ${colors.gray.l70}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.gray.l70}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '&:focus-within': {
        flexDirection: 'column',
        border: 'none',
        justifyContent: 'flex-start',
        '&:hover': {
          border: 'none',
        },
        boxShadow: '0px 1px 3px rgb(0 0 0 / 14%)',
      },
    },
  },
  inputContainer: {
    '&:hover': {
      border: 'none',
    },
    border: 'none',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus-within': {
        background: colors.white,
      },
      marginLeft: '0px',
      width: '100%',
      fontSize: '16px',
    },
  },
  input: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      '&:focus-within': {
        marginLeft: '0px',
      },
    },
  },
  searchInputContainer: {
    minWidth: '265px',
    [theme.breakpoints.down('sm')]: {
      '&:focus-within': {
        minWidth: '0px',
      },
    },
  },
  autocomplete: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  locationInputContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  locationInput: {
    '&::placeholder': {
      opacity: '.5',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchButton: {
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    background: theme.palette.secondary.main,
    color: theme.palette.background.default,
    padding: 10,
    left: '1px',
    width: '46px',
    height: '46px',
    borderRadius: '40px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchButtonText: {
    fontWeight: 600,
    fontSize: '16px',
  },
  divider: {
    height: 28,
    margin: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  listBox: {
    listStyle: 'none',
    left: -50,
    top: 25,
    width: 'calc(100% + 80px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',

    '& > li': {
      cursor: 'pointer',
      backgroundColor: colors.gray.l90,
    },
  },
}));
